/// <reference path="../../typings/index.d.ts" />
import { Pivot, PivotItem } from "@fluentui/react";
import moment from "moment";
import {
    Checkbox, CommandBarButton, DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    Dropdown,
    IColumn, IDetailsList, IDetailsListProps,
    IDropdownOption,
    IDropdownStyles, Image, IStackItemStyles, Label,
    Link, PrimaryButton, SelectionMode,
    Separator, Stack,
    StackItem, TextField,
    Toggle
} from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { getPrivileges } from "src/privileges/actions";
import { getActiveUser } from "src/profile/actions";
import { FileSelector } from "src/spintr/components";
import { IApplicationState } from "src/spintr/reducer";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, Label as SpintrLabel, Loader, PasswordStrengthMeter } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ConfirmDialog from "src/ui/components/Forms/ConfirmDialog";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { Style } from "src/ui/helpers";
import { validateRequiredTextField } from "src/utils";
import { MandatoryText } from "../../ui/components/Forms";
import { TargetPicker } from "./TargetPicker";
import "./UserEditView.scss";
import api from "src/spintr/SpintrApi";

interface IProps {
    dispatch?: (any) => void;
    match: any;
    instance: any;
    sidebarIsExpanded: boolean;
    isSmallViewMode: boolean;
    isUserManager: boolean;
    history?: any;
    currentUser?: any;
    domains?: any[];
    userDepartment: any;
    userDepartments: any[];
    hidePersonalFields?: boolean;
}

interface IState {
    enableFieldValidation?: boolean;
    user: Spintr.ISocialUser;
    isLoading: boolean;
    saveSuccess: boolean;
    saveError: string[];
    departmentOptions: IDropdownOption[];
    userTypes: IDropdownOption[];
    departmentItems: IDetailsListItem[];
    groupItems: IDetailsListItem[];
    pageRightItems: IDetailsListPageRightItem[];
    pageRightListKey: number;
    //initialFocusedIndex: number;
    pages: {};
    itemExpanded: any[];
    showInfoDialog: boolean;
    infoDialogMessage: string;
    showImageSelector: boolean;
    showConfirmDialog: boolean;
    confirmDialogMessage: string;
    validDomains: any[];
    domain: any;
    showConfirmSubmitDialog: boolean;
}

export interface IDetailsListItem {
    key: number;
    name: string;
}

export interface IDetailsListPageRightItem {
    key: number;
    name: string;
    pages: any;
    privileges: any;
}
const stackItemStyles: IStackItemStyles = {
    root: {
        marginRight: 16,
    },
};
class UserEditView extends Component<IProps, IState> {
    private dates: IDropdownOption[];
    private months: IDropdownOption[];
    private languages: IDropdownOption[];
    private _groupList = React.createRef<IDetailsList>();
    private extractDescriptionText: string;
    private anonymizeDescriptionText: any;
    private consentDescriptionText: any;
    public static contextType = ThemeContext;
    private enableAllDomains = false;
    private isNew = window.location.href.indexOf("/create") > -1;

    constructor(props) {
        super(props);
        const { instance } = this.props;
        const instanceName = instance.get("name");
        this.enableAllDomains = instance.get("enableAllDomains");

        this.dates = [];
        for (var i = 1; i < 32; i++) this.dates.push({ key: i, text: i.toString() });

        this.months = moment.months().map((m, i) => {
            return { key: i, text: m };
        });

        this.languages = [
            {
                key: "0",
                text: localize("AnvandGlobalaInstallningen"),
            },
            {
                key: "1",
                text: localize("Swedish"),
            },
            {
                key: "2",
                text: localize("Norwegian"),
            },
            {
                key: "3",
                text: localize("English"),
            },
            {
                key: "4",
                text: localize("German"),
            },
            {
                key: "5",
                text: localize("Finnish"),
            },
            {
                key: "6",
                text: localize("Danish"),
            },
            {
                key: "7",
                text: localize("Italian"),
            },
            {
                key: "8",
                text: localize("Spanish"),
            },
            {
                key: "9",
                text: localize("French"),
            }
        ];

        this.extractDescriptionText = localize("USER_FORM_EXTRACT_DESCRIPTION").replace(/\{INTRANET\}/gm, instanceName);

        this.anonymizeDescriptionText = (user) => {
            return localize("USER_FORM_ANONYMIZE_DESCRIPTION")
                .replace(/\{NAME\}/gm, user.name)
                .replace(/\{INTRANET\}/gm, instanceName);
        };

        this.consentDescriptionText = (consentGiven, user) => {
            if (consentGiven) {
                return localize("USER_FORM_CONSENTED_ON")
                    .replace(/\{NAME\}/gm, user.name)
                    .replace(/\{DATE\}/gm, this.formatDate(user.consentGranted));
            } else {
                return localize("USER_FORM_HAS_NOT_CONSENTED").replace(/\{NAME\}/gm, user.name);
            }
        };

        this.state = {
            isLoading: true,
            user: {
                email: "",
                username: "",
                name: "",
                removedDepartments: [],
                removedRoles: [],
                departments: [],
                address: "",
                address2: "",
                zip: "",
                phone: "",
                cellphone: "",
                privatePhone: "",
                privateEmail: "",
                workTasks: "",
                groups: [],
                generatePassword: true,
                privileges: {
                    news: {
                        canCreate: false,
                    },
                    pages: {
                        canCreate: false,
                    },
                },
            } as Spintr.ISocialUser,
            saveSuccess: new URLSearchParams(props.location.search).get("saveSuccess") == "true",
            saveError: [],
            departmentOptions: [],
            userTypes: [],
            departmentItems: [],
            groupItems: [],
            pageRightItems: [],
            pageRightListKey: 0,
            //initialFocusedIndex: 0,
            pages: {},
            itemExpanded: [],
            showInfoDialog: false,
            infoDialogMessage: "",
            showImageSelector: false,
            showConfirmDialog: false,
            confirmDialogMessage: "",
            validDomains: this.props.domains.map((d, i) => {
                return { key: i + 1, text: `@${d}` };
            }),
            domain: undefined,
            showConfirmSubmitDialog: false,
        };
    }

    private handleUserChange = (evt) => {
        const value = evt.target.value;
        this.setState({
            user: {
                ...this.state.user,
                [evt.target.name]: value,
            },
        });
    };

    private handleUserSelectChange = (name, evt, option, doAfter?) => {
        let rights = {} as any;

        if (name === "type" && option.key === "administrators" ) {
            rights.canModerateFeed = true;
        }

        this.setState(
            {
                user: {
                    ...this.state.user,
                    [name]: option.key,
                    rights: {...this.state.user.rights, ...rights}
                },
            },
            () => {
                if (doAfter) {
                    doAfter();
                }
            }
        );
    };

    private handleUserSettingSelectChange = (name, evt, option) => {
        this.setState({
            user: {
                ...this.state.user,
                settings: {
                    ...this.state.user.settings,
                    [name]: option.key,
                },
            },
        });
    };

    toggleUserRight = (right) => {
        this.setState({
            user: {
                ...this.state.user,
                rights: {
                    ...this.state.user.rights,
                    [right]: !this.state.user.rights[right],
                },
            },
        });
    };

    toggleUserSetting = (setting) => {
        this.setState({
            user: {
                ...this.state.user,
                settings: {
                    ...this.state.user.settings,
                    [setting]: !this.state.user.settings[setting],
                },
            },
        });
    };

    private handleAddGroup = (g) => {
        let newGroup = { id: g.id, name: g.name };
        let newGroupItem = { key: g.id, name: g.name };

        this.setState((prevState) => ({
            user: { ...prevState.user, groups: [...prevState.user.groups, newGroup] },
            groupItems: [...prevState.groupItems, newGroupItem],
        }));
        return null;
    };

    private handleAddDepartment = (g) => {
        let newDepartment = { id: g.id, name: g.name };
        let newDepartmentItem = { key: g.id, name: g.name };

        this.setState((prevState) => ({
            user: { ...prevState.user, departments: [...prevState.user.departments, newDepartment] },
            departmentItems: [...prevState.departmentItems, newDepartmentItem],
        }));
        return null;
    };

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    private save = () => {
        this.setState({
            enableFieldValidation: true,
        });
        this.setState(
            {
                isLoading: true,
                user: {
                    ...this.state.user,
                    email:
                        !this.enableAllDomains && this.state.user.email && this.state.user.email.search("@") === -1
                            ? this.state.user.email + this.state.domain.text
                            : this.state.user.email,
                },
            },
            () => {
                api
                    .post(`/api/v1/users/${this.state.user.id}`, this.state.user)
                    .then((response) => {
                        this.setState(
                            {
                                saveSuccess: true,
                                saveError: [],
                                isLoading: false,
                            },
                            () => {
                                this.props.history.push(
                                    this.props.isUserManager
                                        ? `/people`
                                        : `/admin/users`
                                );
                            }
                        );

                        if (this.state.user.id === this.props.currentUser.id) {
                            this.props.dispatch(getPrivileges());
                            this.props.dispatch(getActiveUser());
                        }
                    })
                    .catch(this.handleCatch);
            }
        );
    };

    private handleSave = (event) => {
        this.setState({ showConfirmSubmitDialog: true });
        event.preventDefault();
    };

    fetchPrivileges(id) {
        const privileges = api.get(`/api/v1/privileges/pages?userId=${id}`);

        privileges.then((response) => {
            let pageRightPrivileges = [];

            response.data.forEach((p) => {
                this.populatePageRightPrivileges(p, pageRightPrivileges);
            });

            if (this.state.user.type === "administrators" || this.state.user.type === "editor") {
                const checkForUndefinedRights = (items) => {
                    for (let item of items) {
                        if (item.privileges["1002"] === undefined) {
                            item.privileges["1002"] = true;
                        }

                        if (item.privileges["1003"] === undefined) {
                            item.privileges["1003"] = true;
                        }

                        if (item.privileges["1004"] === undefined) {
                            item.privileges["1004"] = true;
                        }

                        if (item.pages) {
                            checkForUndefinedRights(item.pages);
                        }
                    }
                };

                checkForUndefinedRights(pageRightPrivileges);
            }

            const setNameOfItems = (items) => {
                for (let item of items) {
                    if (item.isOffice) {
                        item.name += " (" + localize("Kontor") + ")";
                    } else if (item.isDepartment) {
                        item.name += " (" + localize("Avdelning") + ")";
                    }

                    if (item.pages) {
                        setNameOfItems(item.pages);
                    }
                }
            };

            setNameOfItems(pageRightPrivileges);
            setNameOfItems(response.data);

            this.setState({
                pageRightItems: pageRightPrivileges,
                pages: response.data,
            });
        });
    }

    anonymizeUser = (userId) => {
        this.showConfirmDialog(localize("USER_FORM_CONFIRM_ANONYMIZE"));
    };

    public async componentDidMount() {
        const isNew = window.location.href.indexOf("/create") > -1;

        let { id } = this.props.match.params;

        if (isNew) {
            id = 0;
        }

        const userTypes = api.get("/api/v1/users/types");

        const departments = [];

        if (this.props.isUserManager) {
            departments.push({
                key: this.props.userDepartment.id,
                text: `${this.props.instance.get("enableCompanyLevel") ? `${this.props.userDepartment.office.company.name} > ` : ""
                    }${this.props.userDepartment.office.name} > ${this.props.userDepartment.name}`,
            });

            let userDepartments = [...this.props.userDepartments];
            userDepartments = userDepartments.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

            for (const department of userDepartments) {
                departments.push({
                    key: department.id,
                    text: department.name,
                });
            }
        } else {
            const enableCompanyLevel = this.props.instance.get("enableCompanyLevel");
            const units = await api.get("/api/v1/units/simple", {
                params: { includeCompanies: enableCompanyLevel },
            });

            if (enableCompanyLevel) {
                units.data.forEach((company) => {
                    company.offices.forEach((office) => {
                        office.departments.forEach((department) => {
                            departments.push({ key: department.id, text: `${company.name} > ${office.name} > ${department.name}` });
                        });
                    });
                });
            } else {
                units.data.forEach((office) => {
                    office.departments.forEach((department) => {
                        departments.push({ key: department.id, text: `${office.name} - ${department.name}` });
                    });
                });
            }
        }

        this.setState({
            departmentOptions: departments,
        });

        userTypes.then((response) => {
            let userTypes = [];

            response.data.forEach((t) => {
                userTypes.push({ key: t.id, text: localize(t.name) });
            });

            this.setState({ userTypes });
        });

        if (isNew) {
            const user = {
                id: 0,
                type: "user",
                privileges: {
                    pages: {},
                    news: {},
                },
                rights: {},
                settings: {},
                roles: [],
                groups: [],
                departments: [],
                departmentId: departments[0].key,
                generatePassword: true,
            } as Spintr.ISocialUser;

            this.setState(
                {
                    user,
                    isLoading: false,
                    domain: this.state.validDomains?.[0],
                },
                () => {
                    if (!this.props.isUserManager) {
                        this.fetchPrivileges(id);
                    }
                }
            );
        } else {
            const user = api.get<Spintr.ISocialUser>(`/api/v1/users/single?userId=${id}`);

            user.then(
                (response) => {
                    response.data.departments.forEach((d) => {
                        this.setState((state) => ({
                            departmentItems: [...state.departmentItems, { key: d.id, name: d.name }],
                        }));
                    });

                    response.data.groups.forEach((d) => {
                        this.setState((state) => ({ groupItems: [...state.groupItems, { key: d.id, name: d.name }] }));
                    });

                    let privileges = response.data.privileges;

                    if (
                        typeof privileges.news.canCreate === "undefined" &&
                        (response.data.type === "administrators" || response.data.type === "editor")
                    ) {
                        privileges.news.canCreate = true;
                    }

                    if (
                        typeof privileges.pages.canCreate === "undefined" &&
                        (response.data.type === "administrators" || response.data.type === "editor")
                    ) {
                        privileges.pages.canCreate = true;
                    }

                    const { birthdate } = response.data;

                    if (!this.enableAllDomains) {
                        const splitEmail = response.data.email.split("@");
                        response.data.email = splitEmail[0];
                        this.setState({
                            domain: this.state.validDomains.find((d) => {
                                return d.text === `@${splitEmail[1]}`;
                            }),
                        });
                    }

                    this.setState(
                        {
                            user: {
                                ...response.data,
                                birthdate: !!birthdate ? new Date(birthdate) : null,
                                month: !!birthdate ? new Date(birthdate).getMonth() : 0,
                                date: !!birthdate ? new Date(birthdate).getDate() : 1,
                                privileges,
                            },
                            isLoading: false,
                        },
                        () => {
                            if (!this.props.isUserManager) {
                                this.fetchPrivileges(id);
                            }
                        }
                    );
                },
                (reason) => console.log(reason)
            );
        }
    }

    private populatePageRightPrivileges = (p, pageRightPrivileges) => {
        pageRightPrivileges.push({
            key: p.id,
            name: p.name,
            pages: p.pages,
            privileges: p.privileges,
            isTextPage: p.isTextPage,
            isOffice: p.isOffice,
            isDepartment: p.isDepartment,
        });

        return pageRightPrivileges;
    };

    private _departmentColumns = [
        {
            key: "departmentColumn1",
            name: localize("Namn"),
            fieldName: "name",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
        },
        { key: "departmentColumn2", name: "", fieldName: "delete", minWidth: 20, maxWidth: 20, isResizable: false },
    ];

    private _groupColumns = [
        {
            key: "groupColumn1",
            name: localize("Grupp"),
            fieldName: "name",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
        },
        {
            key: "groupColumn2",
            name: localize("FoljerGrupp"),
            fieldName: "follow",
            minWidth: 80,
            maxWidth: 100,
            isResizable: false,
        },
        { key: "groupColumn3", name: "", fieldName: "delete", minWidth: 20, maxWidth: 20, isResizable: false },
    ];

    private _roleColumns = [
        {
            key: "roleColumn1",
            name: localize("Roll"),
            fieldName: "name",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
        },
        { key: "roleColumn2", name: "", fieldName: "delete", minWidth: 20, maxWidth: 20, isResizable: false },
    ];

    private _pageRightColumns = [
        {
            key: "pageRightColumn1",
            name: localize("Namn"),
            fieldName: "name",
            minWidth: 200,
            isResizable: true,
            onRender: (item) => {
                let hasPages = false;
                let found = this.state.pageRightItems.find((p) => p.key === item.key);

                hasPages = (item.pages && item.pages.length > 0) || (found && found.pages.length > 0);

                if (hasPages) {
                    return (
                        <Link className={"linkFGColor"} key={item.key} onClick={() => this._navigate(item)}>
                            {item.name}
                        </Link>
                    );
                } else {
                    return item.name;
                }
            },
        },
        {
            key: "pageRightColumn2",
            name: localize("privileges.pages.createSubpages"),
            fieldName: "createSubpages",
            minWidth: 140,
        },
        { key: "pageRightColumn3", name: localize("Andra"), fieldName: "modify", minWidth: 100 },
        { key: "pageRightColumn4", name: localize("TaBort"), fieldName: "delete", minWidth: 100 },
        { key: "pageRightColumn5", name: localize("ValjAlla"), fieldName: "selectAll", minWidth: 100 },
    ];

    private _onRenderDepartmentColumn = (item: any, index: number, column: IColumn): JSX.Element => {
        if (column.fieldName === "delete") {
            return (
                <CommandBarButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                        this._deleteDepartment(item.key);
                    }}
                />
            );
        }
        return item[column.fieldName];
    };

    private _onRenderGroupColumn = (item: any, index: number, column: IColumn): JSX.Element => {
        if (column.fieldName === "delete") {
            return (
                <CommandBarButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                        this._deleteGroup(item.key);
                    }}
                />
            );
        } else if (column.fieldName === "follow") {
            return (
                <Toggle
                    checked={
                        this.state.user.groups
                            ? this.state.user.groups.find((g) => g.id === item.key).isFollowing
                            : false
                    }
                    onChange={() => {
                        let newGroups = this.state.user.groups;
                        let g = newGroups.find((g) => g.id === item.key);
                        g.isFollowing = !g.isFollowing;

                        this.setState(
                            {
                                user: { ...this.state.user, groups: newGroups },
                            },
                            () => {
                                this._groupList.current.forceUpdate();
                            }
                        );
                    }}
                />
            );
        }
        return item[column.fieldName];
    };

    private setRight = (id, priv, val) => {
        let existingPage = -1;
        let newPage = { id: id, privileges: { [priv]: val } };
        let p = { ...this.state.user.privileges.pages };
        if (p.pages && p.pages.length > 0) {
            existingPage = p.pages.findIndex((page) => page.id === id);
        } else {
            p.pages = [];
        }

        if (existingPage >= 0) {
            p.pages[existingPage].privileges = { ...p.pages[existingPage].privileges, [priv]: val };
        } else {
            p.pages = p.pages.concat(newPage);
        }

        this.setState((ps) => ({
            user: {
                ...ps.user,
                privileges: {
                    ...ps.user.privileges,
                    pages: {
                        ...ps.user.privileges.pages,
                        pages: p.pages,
                    },
                },
            },
        }));
    };

    private setRightChange = (item, priv, val) => {
        let pageRightItems = [...this.state.pageRightItems];

        let idx = pageRightItems.findIndex((r) => r.key === item.key);
        item.privileges[priv] = val;
        pageRightItems[idx] = item;

        this.setState({
            pageRightItems,
        });
        this.setRight(item.key, priv, item.privileges[priv]);
    };

    private toggleRightChangeAll = async (item) => {
        if (item.privileges[1002] && item.privileges[1003] && item.privileges[1004]) {
            await this.setRightChange(item, 1002, false);
            await this.setRightChange(item, 1003, false);
            await this.setRightChange(item, 1004, false);
        } else {
            await this.setRightChange(item, 1002, true);
            await this.setRightChange(item, 1003, true);
            await this.setRightChange(item, 1004, true);
        }
    };

    private _onRenderPageRightColumn = (item: any, index: number, column: IColumn): JSX.Element => {
        if (!item.isTextPage) {
            return null;
        }

        if (column.fieldName === "createSubpages") {
            return (
                <Checkbox
                    checked={item.privileges[1002]}
                    onChange={(ev) => {
                        this.setRightChange(item, 1002, !item.privileges[1002]);
                    }}
                />
            );
        } else if (column.fieldName === "modify") {
            return (
                <Checkbox
                    checked={item.privileges[1003]}
                    onChange={(ev) => {
                        this.setRightChange(item, 1003, !item.privileges[1003]);
                    }}
                />
            );
        } else if (column.fieldName === "delete") {
            return (
                <Checkbox
                    checked={item.privileges[1004]}
                    onChange={(ev) => {
                        this.setRightChange(item, 1004, !item.privileges[1004]);
                    }}
                />
            );
        } else if (column.fieldName === "selectAll") {
            return (
                <Checkbox
                    checked={item.privileges[1002] && item.privileges[1003] && item.privileges[1004]}
                    onChange={() => {
                        this.toggleRightChangeAll(item);
                    }}
                />
            );
        }
        return item[column.fieldName];
    };

    private _onRenderPageRightRow: IDetailsListProps["onRenderRow"] = (props) => {
        const { itemExpanded } = this.state;

        if (itemExpanded.includes(props.item.key)) {
            return (
                <>
                    <DetailsRow {...props} />
                    <DetailsList
                        items={this._subPagesAsItems(props.item)}
                        columns={this._pageRightColumns}
                        isHeaderVisible={false}
                        onItemInvoked={this._navigate}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        //initialFocusedIndex={this.state.initialFocusedIndex}
                        onRenderRow={this._onRenderPageRightRow}
                        onRenderItemColumn={this._onRenderPageRightColumn}
                    />
                </>
            );
        } else {
            return <DetailsRow {...props} />;
        }
    };

    private _subPagesAsItems = (item) => {
        let foo = [];

        if (item) {
            item.pages.forEach((s) => {
                this.populatePageRightPrivileges(s, foo);
            });
        }

        return foo;
    };

    private formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    private _deleteDepartment = (id) => {
        let dep = [];
        this.setState(
            {
                user: {
                    ...this.state.user,
                    departments: this.state.user.departments.filter((item) => item.id !== id),
                    removedDepartments: this.state.user.removedDepartments
                        ? this.state.user.removedDepartments.concat(
                            this.state.user.departments.find((item) => item.id === id)
                        )
                        : [this.state.user.departments.find((item) => item.id === id)],
                },
                departmentItems: [],
            },
            () => {
                this.state.user.departments.forEach((d) => {
                    dep.push({ key: d.id, name: d.name });
                });
                this.setState({ departmentItems: dep });
            }
        );
    };

    private _deleteGroup = (id) => {
        let groups = [];
        this.setState(
            {
                user: {
                    ...this.state.user,
                    groups: this.state.user.groups.filter((item) => item.id !== id),
                    removedGroups: this.state.user.removedGroups
                        ? this.state.user.removedGroups.concat(this.state.user.groups.find((item) => item.id === id))
                        : [this.state.user.groups.find((item) => item.id === id)],
                },
                groupItems: [],
            },
            () => {
                this.state.user.groups.forEach((d) => {
                    groups.push({ key: d.id, name: d.name });
                });
                this.setState({ groupItems: groups });
            }
        );
    };

    onConfirmedAnonymizeUser = () => {
        this.setState(
            {
                showConfirmDialog: false,
                confirmDialogMessage: "",
                isLoading: true,
            },
            () => {
                api.post(`/api/v1/users/${this.state.user.id}/anonymize`).then((_response) => {
                    this.setState({ isLoading: false });
                    this.props.history.push(`/admin/users`);
                });
            }
        );
    };

    recursivelySetAll = (list, val) => {
        list.map((i) => {
            i.privileges["1002"] = val;
            i.privileges["1003"] = val;
            i.privileges["1004"] = val;

            if (i.pages && i.pages.length > 0) {
                this.recursivelySetAll(i.pages, val);
            }

            return;
        });
    };

    toggleCheckAll = (checked) => {
        let pageRightItems = [...this.state.pageRightItems];

        this.recursivelySetAll(pageRightItems, checked);

        let flatPageList = [];

        let addToFlatPageList = (items) => {
            for (let item of items) {
                if (item.isTextPage) {
                    flatPageList.push(item);
                }

                if (item.pages) {
                    addToFlatPageList(item.pages);
                }
            }
        };

        addToFlatPageList(this.state.pageRightItems);

        this.setState({
            pageRightItems,
            user: {
                ...this.state.user,
                privileges: {
                    ...this.state.user.privileges,
                    pages: {
                        ...this.state.user.privileges.pages,
                        pages: flatPageList.map((pri) => {
                            return {
                                id: pri.key ? pri.key : pri.id,
                                privileges: {
                                    1002: checked,
                                    1003: checked,
                                    1004: checked,
                                },
                            };
                        }),
                    },
                },
            },
        });
    };

    public render() {
        const { handleUserChange, handleUserSelectChange, handleUserSettingSelectChange } = this;
        const {
            user,
            saveSuccess,
            saveError,
            departmentOptions,
            userTypes,
            showInfoDialog,
            infoDialogMessage,
            isLoading,
        } = this.state;

        const { instance, isUserManager } = this.props;
        const enablePrivilegesMatrix = instance.get("enablePrivilegesMatrix");
        const enableUserManager = instance.get("enableUserManager");
        const enableUserPK = instance.get("enableUserPK");
        const enableUserResources = instance.get("enableUserResources");
        const enableRestrictedGroups = instance.get("restrictGroups");

        const enableAzureUserMirroring = instance.get('enableAzureUserMirroring') as boolean;

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };

        const dropdownDateStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 100 },
        };

        if (isLoading) return <Loader />;

        return (
            <div className="UserEditView">
                <Helmet>
                    <title>{localize("Medarbetare")}</title>
                </Helmet>

                <ConfirmDialog
                    show={this.state.showConfirmSubmitDialog}
                    title={user.id === 0 ? localize("SkapaInnehall") : localize("AndraInnehall")}
                    message={
                        user.id === 0
                            ? localize("ArDuSakerPaAttDuVillX").replace(
                                "{{X}}",
                                localize("SkapaAnvandare").toLowerCase()
                            )
                            : localize("ArDuSakerPaAttDuVillX").replace(
                                "{{X}}",
                                localize("RedigeraAnvandare").toLowerCase()
                            )
                    }
                    onDismiss={() => {
                        this.setState({ showConfirmSubmitDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showConfirmSubmitDialog: false }, () => {
                            this.save();
                        });
                    }}
                />

                {showInfoDialog && (
                    <InfoDialog
                        onDismiss={() => {
                            this.setState({ showInfoDialog: false });
                        }}
                        show={showInfoDialog}
                        message={infoDialogMessage}
                    />
                )}

                {this.state.showConfirmDialog && (
                    <CustomDialog
                        message={this.state.confirmDialogMessage}
                        show={this.state.showConfirmDialog}
                        onDismiss={() => {
                            this.setState({ showConfirmDialog: false });
                        }}
                        onConfirm={() => {
                            this.onConfirmedAnonymizeUser();
                        }}
                    />
                )}
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}

                <Breadcrumbs
                    items={[
                        {
                            text: localize("Medarbetare"),
                            key: 2,
                            link: "/people",
                        },
                        {
                            key: 3,
                            text: this.state.user.id === 0 ? localize("SkapaAnvandare") : localize("RedigeraAnvandare"),
                            link: ``,
                        },

                    ]}
                />
                <form onSubmit={this.handleSave} id="userform">
                    <div className="createUserWrap page-margin-bottom">
                        <h1 className="fs-h1">
                            {this.state.user.id === 0 ? localize("SkapaAnvandare") : localize("RedigeraAnvandare")}
                        </h1>
                        <MandatoryText />
                        <Pivot aria-label="Pivot pages" overflowBehavior={"menu"}>
                            <PivotItem headerText={localize("AnvandarKonto")}>
                                <FormSection>
                                    <FormControl>
                                        <TextField
                                            label={localize("Namn")}
                                            value={user.name}
                                            name="name"
                                            onChange={handleUserChange}
                                            required
                                            aria-required
                                            validateOnFocusIn
                                            validateOnFocusOut
                                            validateOnLoad={!!this.state.enableFieldValidation}
                                            onGetErrorMessage={validateRequiredTextField}
                                        />
                                    </FormControl>

                                    <Stack horizontal className={"emailAndDomain"}>
                                        <StackItem grow={1}>
                                            <FormControl>
                                                <TextField
                                                    required
                                                    aria-required
                                                    validateOnFocusIn
                                                    validateOnFocusOut
                                                    validateOnLoad={!!this.state.enableFieldValidation}
                                                    onGetErrorMessage={validateRequiredTextField}
                                                    label={localize("Epost")}
                                                    value={user.email}
                                                    name="email"
                                                    onChange={handleUserChange}
                                                />
                                            </FormControl>
                                        </StackItem>

                                        {!this.enableAllDomains && (
                                            <StackItem grow={1}>
                                                <FormControl>
                                                    <Dropdown
                                                        label={localize("DOMAIN_HEADER")}
                                                        selectedKey={this.state.domain?.key}
                                                        required
                                                        aria-required
                                                        onChange={(evt, option) => {
                                                            this.setState({
                                                                domain: option,
                                                            });
                                                        }}
                                                        options={this.state.validDomains}
                                                    // placeholder={localize("SELECT_TAG")}
                                                    />
                                                </FormControl>
                                            </StackItem>
                                        )}
                                    </Stack>

                                    <FormControl>
                                        <TextField
                                            label={localize("Anvandarnamn")}
                                            value={user.username}
                                            name="username"
                                            onChange={handleUserChange}
                                            required
                                            aria-required
                                            validateOnFocusIn
                                            validateOnFocusOut
                                            validateOnLoad={!!this.state.enableFieldValidation}
                                            onGetErrorMessage={validateRequiredTextField}
                                        />
                                    </FormControl>
                                    {this.state.user.id === 0 && (
                                        <span>
                                            <FormControl>
                                                <TextField
                                                    label={localize("Losenord")}
                                                    value={user.password}
                                                    name="password"
                                                    onChange={handleUserChange}
                                                    required={!user.generatePassword}
                                                    disabled={user.generatePassword}
                                                    aria-required
                                                    validateOnFocusIn
                                                    validateOnFocusOut
                                                    validateOnLoad={!!this.state.enableFieldValidation}
                                                    onGetErrorMessage={validateRequiredTextField}
                                                />
                                                {!user.generatePassword && (
                                                    <PasswordStrengthMeter
                                                        password={user?.password}
                                                    />
                                                )}
                                            </FormControl>

                                            <FormControl>
                                                <Checkbox
                                                    className="generatePassword"
                                                    label={localize("GenereraLosenordOchMaila")}
                                                    checked={user.generatePassword}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.setState({
                                                            user: {
                                                                ...this.state.user,
                                                                generatePassword: checked,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                        </span>
                                    )}
                                    {enableAzureUserMirroring && (
                                        <>
                                            <FormControl>
                                                <Checkbox
                                                    className="mirrorToAzureAD"
                                                    label={localize("MIRROR_USER_TO_AZURE")}
                                                    checked={user.mirrorToAzureAD}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.setState({
                                                            user: {
                                                                ...this.state.user,
                                                                mirrorToAzureAD: checked,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                            {user.mirrorToAzureAD && (
                                                <FormControl>
                                                    <TextField
                                                        label={"UserPrincipalName"}
                                                        value={user.userPrincipalName}
                                                        name="userPrincipalName"
                                                        onChange={handleUserChange}
                                                        required
                                                        aria-required
                                                        validateOnFocusIn
                                                        validateOnFocusOut
                                                        validateOnLoad={!!this.state.enableFieldValidation}
                                                        onGetErrorMessage={validateRequiredTextField}
                                                    />
                                                </FormControl>
                                            )}
                                        </>
                                    )}
                                    {userTypes.length > 0 && !isUserManager && (
                                        <FormControl>
                                            <Dropdown
                                                label={localize("Anvandartyp")}
                                                defaultSelectedKey={user.type}
                                                options={userTypes}
                                                styles={dropdownStyles}
                                                onChange={(evt, option) => {
                                                    handleUserSelectChange("type", evt, option);
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    <FormSection title={`${localize("appOrganisations")}`}>
                                        <FormControl>
                                            <Dropdown
                                                label={localize("PrimarAvdelning")}
                                                defaultSelectedKey={user.departmentId}
                                                options={departmentOptions}
                                                styles={{ dropdown: { width: 450 } }}
                                                onChange={(evt, option) => {
                                                    handleUserSelectChange("departmentId", evt, option);
                                                }}
                                            />
                                        </FormControl>
                                    </FormSection>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            items={this.state.user.roles.map((i) => {
                                                return {
                                                    ...i,
                                                    key: i.key ? i.key : i.id,
                                                    id: i.id ? i.id : i.key,
                                                    subText: localize("Roll"),
                                                };
                                            })}
                                            types={[SpintrTypes.UberType.Role]}
                                            label={localize("Roller")}
                                            enableNewItems={!isUserManager}
                                            placeholder={!isUserManager && localize("SokRoll")}
                                            onChange={(items) => {
                                                let newRoles = [];

                                                for (const i of items) {
                                                    if (
                                                        !newRoles.find(
                                                            (r) => r.name.toLowerCase() === i.name.toLowerCase()
                                                        )
                                                    ) {
                                                        newRoles.push({
                                                            ...i,
                                                            id: isNaN(i.key) ? 0 : i.key,
                                                            isNew: i.isNewItem,
                                                        });
                                                    }
                                                }

                                                if (!this.isNew) {
                                                    const oldRoles = this.state.user.roles.filter(
                                                        (oldRole) => oldRole.id
                                                    );
                                                    const removedRoles = [...this.state.user.removedRoles];
                                                    const removedRole = oldRoles.find(
                                                        (oldRole) =>
                                                            !newRoles.some((newRole) => newRole.id === oldRole.id)
                                                    );

                                                    if (removedRole) {
                                                        removedRoles.push(removedRole);
                                                    }

                                                    this.setState({
                                                        user: {
                                                            ...this.state.user,
                                                            roles: newRoles,
                                                            removedRoles,
                                                        },
                                                    });
                                                } else {
                                                    this.setState({
                                                        user: {
                                                            ...this.state.user,
                                                            roles: newRoles,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </FormControl>

                                    {!isUserManager && (
                                        <FormSection title={`${localize("Ledare")}`}>
                                            <FormControl>
                                                <Toggle
                                                    label={localize("LedareForKontor")}
                                                    checked={user.rights.officeLeader}
                                                    onText={localize("Ja")}
                                                    offText={localize("Nej")}
                                                    onChange={() => {
                                                        this.toggleUserRight("officeLeader");
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Toggle
                                                    label={localize("LedareForAvdelning")}
                                                    checked={user.rights.departmentLeader}
                                                    onText={localize("Ja")}
                                                    offText={localize("Nej")}
                                                    onChange={() => {
                                                        this.toggleUserRight("departmentLeader");
                                                    }}
                                                />
                                            </FormControl>
                                        </FormSection>
                                    )}
                                    {this.state.user.id > 0 && (
                                        <FormSection title={`${localize("Alternativ")}`}>
                                            <FormControl>
                                                <PrimaryButton
                                                    text={localize("SkickaEttNyGenereratLosenord")}
                                                    onClick={() => {
                                                        this.sendNewPassword(user.id);
                                                    }}
                                                />
                                            </FormControl>
                                            {user.isEligibleForNewInvite && (
                                                <FormControl>
                                                    <PrimaryButton
                                                        text={localize("SkickaInbjudan")}
                                                        onClick={() => {
                                                            this.sendInvite(user.id);
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                            <FormControl>
                                                {user.rights && user.rights.locked ? (
                                                    <PrimaryButton
                                                        text={localize("LasUppAnvandare")}
                                                        onClick={this.toggleLockUser}
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        text={localize("LasAnvandare")}
                                                        onClick={this.toggleLockUser}
                                                    />
                                                )}
                                            </FormControl>
                                            <FormControl>
                                                {user.rights && user.rights.deleted ? (
                                                    <PrimaryButton
                                                        text={localize("AktiveraAnvandare")}
                                                        onClick={this.toggleDeactivateUser}
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        text={localize("InaktiveraAnvandare")}
                                                        onClick={this.toggleDeactivateUser}
                                                    />
                                                )}
                                            </FormControl>
                                            <FormControl>
                                                {user.rights && user.authenticationMechanisms?.length > 0 && (
                                                    <PrimaryButton
                                                        text={localize("REMOVE_MFA")}
                                                        onClick={this.onRemoveMfaClick}
                                                    />
                                                )}
                                            </FormControl>
                                        </FormSection>
                                    )}

                                    {this.state.user.id > 0 && (
                                        <FormSection title={`${localize("GDPR")}`}>
                                            <SpintrLabel as="p" size="body-4">
                                                {user.consentGranted
                                                    ? this.consentDescriptionText(true, user)
                                                    : this.consentDescriptionText(false, user)}
                                            </SpintrLabel>
                                            <SpintrLabel as="p" size="body-4">
                                                {this.extractDescriptionText}
                                            </SpintrLabel>
                                            <FormControl>
                                                <PrimaryButton
                                                    text={localize("USER_FORM_EXTRACT_RECORDS")}
                                                    onClick={() => {
                                                        // @ts-ignore
                                                        window.location = `/api/v1/users/${user.id}/personal-data`;
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </FormControl>

                                            <Label>{localize("USER_FORM_ANONYMIZE_HEADER")}</Label>
                                            <SpintrLabel as="p" size="body-4">
                                                {this.anonymizeDescriptionText(user)}{" "}
                                                {localize("USER_FORM_CANNOT_UNDO")}
                                            </SpintrLabel>
                                            <FormControl>
                                                <PrimaryButton
                                                    text={localize("USER_FORM_DEACTIVATE_USER")}
                                                    onClick={() => {
                                                        this.anonymizeUser(user.id);
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </FormControl>
                                        </FormSection>
                                    )}
                                </FormSection>
                            </PivotItem>
                            <PivotItem
                                headerText={localize("Profilinstallningar")}
                                headerButtonProps={{
                                    "data-order": 1,
                                    "data-title": "Information",
                                }}
                            >
                                <FormSection>
                                    <>
                                        <>
                                            <FormControl label={localize("Profilbild")}>
                                                {!user.image && (
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            this.setState({
                                                                showImageSelector: true,
                                                            });
                                                        }}
                                                    >
                                                        {localize("ValjBild")}
                                                    </PrimaryButton>
                                                )}
                                                {user.image && (
                                                    <div>
                                                        <Image src={user.image} />
                                                        <FormControl>
                                                            <PrimaryButton
                                                                style={{ marginTop: Style.getSpacingStr(3) }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        user: {
                                                                            ...this.state.user,
                                                                            hasImage: false,
                                                                            image: "",
                                                                            imageTicket: {
                                                                                ticket: "",
                                                                                remove: true,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                {localize("TaBortNuvarande")}
                                                            </PrimaryButton>
                                                        </FormControl>
                                                    </div>
                                                )}
                                                {this.state.showImageSelector && (
                                                    <FileSelector
                                                        cropImage
                                                        cropShape="round"
                                                        cropAspect={1}
                                                        renderSizeString={() => {
                                                            return (
                                                                `${localize(
                                                                    "OptimalBildStorlek"
                                                                )}: 100x100px <br />`
                                                            )
                                                        }}
                                                        onClose={() => {
                                                            this.setState({
                                                                showImageSelector: false,
                                                            });
                                                        }}
                                                        onSelect={(data) => {
                                                            this.setState({
                                                                showImageSelector: false,
                                                                user: {
                                                                    ...this.state.user,
                                                                    image: data[0].thumbnailUrl,
                                                                    hasImage: true,
                                                                    imageTicket: {
                                                                        ticket: data[0].ticket,
                                                                        remove: false,
                                                                    },
                                                                },
                                                            });
                                                        }}
                                                        allowMultipleFiles={false}
                                                        fileTypesString={"image/png, image/jpeg"}
                                                        fileTypes={["png", "jpeg", "jpg"]}
                                                        sourcesToDisplay={[0]}
                                                        fileUploadType={1}
                                                    />
                                                )}
                                            </FormControl>
                                            <FormControl>
                                                <Toggle
                                                    label={localize("VisaFodelsedatum")}
                                                    checked={!!user.birthdate}
                                                    onText={localize("Ja")}
                                                    offText={localize("Nej")}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.setState({
                                                            user: {
                                                                ...user,
                                                                birthdate: checked
                                                                    ? new Date(
                                                                        Date.UTC(
                                                                            1970,
                                                                            this.state.user.month,
                                                                            this.state.user.date
                                                                        )
                                                                    )
                                                                    : null,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                            {user.birthdate && (
                                                <>
                                                    <Stack
                                                        tokens={{ childrenGap: 3, padding: 0 }}
                                                        horizontal
                                                        verticalAlign="start"
                                                    >
                                                        <StackItem styles={stackItemStyles}>
                                                            <FormControl>
                                                                <Dropdown
                                                                    label={localize("Fodelsedatum")}
                                                                    selectedKey={user.date}
                                                                    options={this.dates}
                                                                    styles={dropdownDateStyles}
                                                                    onChange={(evt, option) => {
                                                                        handleUserSelectChange(
                                                                            "date",
                                                                            evt,
                                                                            option,
                                                                            () => {
                                                                                this.setState({
                                                                                    user: {
                                                                                        ...this.state.user,
                                                                                        birthdate: new Date(
                                                                                            Date.UTC(
                                                                                                1970,
                                                                                                this.state.user.month,
                                                                                                this.state.user.date
                                                                                            )
                                                                                        ),
                                                                                    },
                                                                                });
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </StackItem>
                                                        <StackItem styles={stackItemStyles}>
                                                            <FormControl>
                                                                <Dropdown
                                                                    label="&nbsp;"
                                                                    selectedKey={user.month}
                                                                    options={this.months}
                                                                    styles={dropdownDateStyles}
                                                                    onChange={(evt, option) => {
                                                                        handleUserSelectChange(
                                                                            "month",
                                                                            evt,
                                                                            option,
                                                                            () => {
                                                                                this.setState({
                                                                                    user: {
                                                                                        ...this.state.user,
                                                                                        birthdate: new Date(
                                                                                            Date.UTC(
                                                                                                1970,
                                                                                                this.state.user.month,
                                                                                                this.state.user.date
                                                                                            )
                                                                                        ),
                                                                                    },
                                                                                });
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </StackItem>
                                                    </Stack>
                                                </>
                                            )}
                                            <FormControl>
                                                <TextField
                                                    label={localize("Telefonnummer")}
                                                    value={user.phone}
                                                    name="phone"
                                                    onChange={handleUserChange}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    label={localize("Mobilnummer")}
                                                    value={user.cellphone}
                                                    name="cellphone"
                                                    onChange={handleUserChange}
                                                />
                                            </FormControl>
                                            {!this.props.hidePersonalFields && (
                                                <>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("PersonligTelefon")}
                                                            value={user.privatePhone}
                                                            name="privatePhone"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("PersonligEpost")}
                                                            value={user.privateEmail}
                                                            name="privateEmail"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("Adress")}
                                                            value={user.address}
                                                            name="address"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("Adress") + " 2"}
                                                            value={user.address2}
                                                            name="address2"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("Postnummer")}
                                                            value={user.zip}
                                                            name="zip"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("Ort")}
                                                            value={user.city}
                                                            name="city"
                                                            onChange={handleUserChange}
                                                        />
                                                    </FormControl>
                                                </>
                                            )}
                                            <FormControl>
                                                <TextField
                                                    label={localize("Arbetsuppgifter")}
                                                    value={user.workTasks}
                                                    name="workTasks"
                                                    onChange={handleUserChange}
                                                    multiline
                                                    rows={3}
                                                />
                                            </FormControl>

                                            {user.settings && (
                                                <>
                                                    <FormControl>
                                                        <Dropdown
                                                            label={localize("Sprak")}
                                                            defaultSelectedKey={user.settings.language}
                                                            options={this.languages}
                                                            styles={dropdownStyles}
                                                            onChange={(evt, option) => {
                                                                handleUserSettingSelectChange("language", evt, option);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {enableUserResources && (
                                                        <FormControl>
                                                            <Toggle
                                                                label={localize("Prioriterad2")}
                                                                checked={user.settings.isPrioritized}
                                                                onText={localize("Ja")}
                                                                offText={localize("Nej")}
                                                                onChange={(evt, option) => {
                                                                    this.setState({
                                                                        user: {
                                                                            ...user,
                                                                            settings: {
                                                                                ...user.settings,
                                                                                isPrioritized: option,
                                                                                isPrioritizedId: option ? 1 : 0,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </>
                                </FormSection>
                            </PivotItem>

                            <PivotItem headerText={localize("Avdelningar")}>
                                <FormControl>
                                    <TargetPicker
                                        existingIds={this.state.departmentItems.map((g) => {
                                            return g.key;
                                        })}
                                        itemSelected={(g) => this.handleAddDepartment(g)}
                                        types={[SpintrTypes.UberType.Department]}
                                        ownDepartmentsOnly={isUserManager}
                                    />
                                </FormControl>

                                <DetailsList
                                    items={this.state.departmentItems}
                                    columns={this._departmentColumns}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    onRenderItemColumn={this._onRenderDepartmentColumn}
                                />
                            </PivotItem>

                            <PivotItem headerText={localize("Grupper")}>
                                <FormControl>
                                    <TargetPicker
                                        existingIds={this.state.groupItems.map((g) => {
                                            return g.key;
                                        })}
                                        itemSelected={(g) => this.handleAddGroup(g)}
                                        types={[SpintrTypes.UberType.Group]}
                                    />
                                </FormControl>

                                <DetailsList
                                    componentRef={this._groupList}
                                    items={this.state.groupItems}
                                    columns={this._groupColumns}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    onRenderItemColumn={this._onRenderGroupColumn}
                                />
                            </PivotItem>

                            {!isUserManager && (
                                <PivotItem headerText={localize("privileges.tabTitle")}>
                                    <FormSection title={localize("privileges.tabTitle")}>
                                        <FormSection>
                                            <FormControl>
                                                <Toggle
                                                    label={localize("IS_BETA_TESTER")}
                                                    checked={user.settings.isBetaTester}
                                                    onText={localize("Ja")}
                                                    offText={localize("Nej")}
                                                    onChange={() => {
                                                        this.toggleUserSetting("isBetaTester");
                                                    }}
                                                />
                                            </FormControl>
                                        </FormSection>
                                        {user.rights && (
                                            <>
                                                <FormSection>
                                                    <FormControl>
                                                        <Toggle
                                                            label={localize("AnvandarenKanModereraFlode")}
                                                            checked={user.rights.canModerateFeed}
                                                            onText={localize("Ja")}
                                                            offText={localize("Nej")}
                                                            onChange={() => {
                                                                this.toggleUserRight("canModerateFeed");
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormSection>

                                                <FormSection>
                                                    <FormControl>
                                                        <Toggle
                                                            label={localize("RattAttLaddaUppFilerIFilbank")}
                                                            checked={user.rights.filebank}
                                                            onText={localize("Ja")}
                                                            offText={localize("Nej")}
                                                            onChange={() => {
                                                                this.toggleUserRight("filebank");
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormSection>
                                                {enableUserManager && (
                                                    <FormSection>
                                                        <FormControl>
                                                            <Toggle
                                                                label={localize(
                                                                    "RattAttAdministreraAnvandareISinAvdelning"
                                                                )}
                                                                checked={user.rights.usermanager}
                                                                onText={localize("Ja")}
                                                                offText={localize("Nej")}
                                                                onChange={() => {
                                                                    this.toggleUserRight("usermanager");
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </FormSection>
                                                )}

                                                {enableRestrictedGroups && (
                                                    <FormSection>
                                                        <FormControl>
                                                            <Toggle
                                                                label={localize("CAN_CREATE_GROUPS")}
                                                                checked={user.settings.canCreateGroups}
                                                                onText={localize("Ja")}
                                                                offText={localize("Nej")}
                                                                onChange={() => {
                                                                    this.toggleUserSetting("canCreateGroups");
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </FormSection>
                                                )}

                                                {enableUserResources && (
                                                    <FormSection title={`${localize("Anstallningshantering")}`}>
                                                        <FormControl>
                                                            <Toggle
                                                                label={localize("FarHanteraAnstallning")}
                                                                checked={user.settings.canEditEmployment}
                                                                onText={localize("Ja")}
                                                                offText={localize("Nej")}
                                                                onChange={() => {
                                                                    this.toggleUserSetting("canEditEmployment");
                                                                }}
                                                            />
                                                            <i>{localize("RESOURCES_RIGHT_INFO1")}</i>
                                                        </FormControl>
                                                        <FormControl>
                                                            <Toggle
                                                                label={localize("FarHanteraAllaAnstallningar")}
                                                                checked={user.settings.canEditAllEmployments}
                                                                onText={localize("Ja")}
                                                                offText={localize("Nej")}
                                                                onChange={() => {
                                                                    this.toggleUserSetting("canEditAllEmployments");
                                                                }}
                                                            />
                                                            <i>{localize("RESOURCES_RIGHT_INFO2")}</i>
                                                        </FormControl>
                                                    </FormSection>
                                                )}
                                            </>
                                        )}
                                    </FormSection>
                                    {enablePrivilegesMatrix && (
                                        <FormSection title={`${localize("Rattighetsmatris")}`}>
                                            <Pivot overflowBehavior={"menu"}>
                                                <PivotItem headerText={localize("Textsidor")}>
                                                    <FormControl>
                                                        <Toggle
                                                            label={localize("privileges.pages.canCreate")}
                                                            checked={user.privileges.pages.canCreate}
                                                            onText={localize("Ja")}
                                                            offText={localize("Nej")}
                                                            onChange={() => {
                                                                this.setState({
                                                                    user: {
                                                                        ...user,
                                                                        privileges: {
                                                                            ...user.privileges,
                                                                            pages: {
                                                                                ...user.privileges.pages,
                                                                                canCreate:
                                                                                    !user.privileges.pages.canCreate,
                                                                            },
                                                                        },
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </FormControl>

                                                    {this.state.pageRightItems && (
                                                        <>
                                                            <Separator />
                                                            <Checkbox
                                                                label={localize("ValjAlla")}
                                                                //checked={}
                                                                onChange={(ev, checked?) => {
                                                                    this.toggleCheckAll(checked);
                                                                }}
                                                            />
                                                            <DetailsList
                                                                key={this.state.pageRightListKey}
                                                                items={this.state.pageRightItems}
                                                                columns={this._pageRightColumns}
                                                                onItemInvoked={this._navigate}
                                                                selectionMode={SelectionMode.none}
                                                                layoutMode={DetailsListLayoutMode.justified}
                                                                //initialFocusedIndex={this.state.initialFocusedIndex}
                                                                onRenderRow={this._onRenderPageRightRow}
                                                                onRenderItemColumn={this._onRenderPageRightColumn}
                                                            />
                                                        </>
                                                    )}
                                                </PivotItem>
                                                <PivotItem headerText={localize("Nyheter")}>
                                                    <FormControl>
                                                        <Toggle
                                                            label={localize("privileges.news.canCreate")}
                                                            checked={user.privileges.news.canCreate}
                                                            onText={localize("Ja")}
                                                            offText={localize("Nej")}
                                                            onChange={() => {
                                                                this.setState({
                                                                    user: {
                                                                        ...user,
                                                                        privileges: {
                                                                            ...user.privileges,
                                                                            news: {
                                                                                ...user.privileges.news,
                                                                                canCreate:
                                                                                    !user.privileges.news.canCreate,
                                                                            },
                                                                        },
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </PivotItem>
                                            </Pivot>
                                        </FormSection>
                                    )}
                                </PivotItem>
                            )}
                        </Pivot>
                    </div>
                    <FormFooterBar onCancelClick={this.onCancel.bind(this)} saveText={localize("Spara")} />
                </form>
            </div>
        );
    }

    private onRemoveMfaClick = async () => {
        try {
            const response = await api.delete("/api/v1/profile/settings/mfa", {
                params: { userId: this.state.user.id, },
            });

            if (response.status > 399)  {
                return;
            }

            this.setState({
                ...this.state,
                user: {
                    ...this.state.user,
                    authenticationMechanisms: [],
                },
            }, () => this.showInfoDialog(localize("MFA_REMOVED")));
        } catch (_) { }
    };

    private onCancel(): void {
        this.props.history.goBack();
    }

    private sendNewPassword = (id) => {
        const { user } = this.state;

        api.put("/api/v1/users/" + id + "/newpassword").then(() => {
            this.showInfoDialog(localize("EttMeddelandeMedLosenordetHarSkickatsTill") + " " + user.email);
        });
    };

    private sendInvite = (id) => {
        api.put("/api/v1/users/" + id + "/sendinvite").then(() => {
            this.showInfoDialog(localize("InbjudanHarSkickats"));
        });
    };

    private toggleLockUser = () => {
        const { user } = this.state;

        api.put(`/api/v1/users/${user.id}/togglelocked`).then(() => {
            this.setState({ user: { ...user, rights: { ...user.rights, locked: !user.rights.locked } } });
            let message = "";
            if (user.rights.locked) {
                message = localize("AnvandarenHarLastsUpp");
            } else {
                message = localize("AnvandarenHarLasts");
            }

            this.showInfoDialog(message);
        });
    };

    private toggleDeactivateUser = () => {
        const { user } = this.state;

        api.put(`/api/v1/users/${user.id}/toggledeleted`).then(() => {
            this.setState({ user: { ...user, rights: { ...user.rights, deleted: !user.rights.deleted } } });
            let message = "";
            if (user.rights.deleted) {
                message = localize("AnvandarenHarAktiverats");
            } else {
                message = localize("AnvandarenHarInaktiverats");
            }

            this.showInfoDialog(message);
        });
    };

    private showInfoDialog = (message) => {
        this.setState({
            showInfoDialog: true,
            infoDialogMessage: message,
        });
    };

    private showConfirmDialog = (message) => {
        this.setState({
            showConfirmDialog: true,
            confirmDialogMessage: message,
        });
    };

    private _navigate = (item) => {
        let { itemExpanded } = this.state;

        if (!item.pages || item.pages.length === 0) {
            return;
        }

        this.setState({
            //pageRightItems: [],
            //initialFocusedIndex: 0,
            // Simulate navigation by updating the list's key, which causes it to re-render
            pageRightListKey: this.state.pageRightListKey + 1,
            itemExpanded: itemExpanded.includes(item.key)
                ? itemExpanded.filter((i) => i !== item.key)
                : itemExpanded.concat(item.key),
        });
    };
}

const mapStateToProps = (state: IApplicationState, props) => {
    const user = state.profile.active;
    const roles = user.roles;

    return {
        ...props,
        currentUser: user,
        instance: state.instance,
        isSmallViewMode: state.ui.isSmallViewMode,
        isUserManager: roles.includes("usermanager") && !state.profile.active.isAdmin,
        userDepartment: state.profile.active.department,
        userDepartments: state.profile.active.departments,
        sidebarIsExpanded: state.ui.sidebarExpanded,
        domains: state.instance.get("domains"),
        hidePersonalFields: state.instance.get("hidePersonalFields"),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(UserEditView));
